<template>
  <div>
    <div
      v-for="(fac, f) of fabricsFactories"
      :key="`fab-${f}`"
      size="A4"
      class="credit-note">
      <div class="d-flex">
        <div class="w-haft">
          <img
            width="50"
            height="50"
            alt="gw"
            src="https://s3-ap-southeast-1.amazonaws.com/storage-oms.akitahub/20200615111959uYgipGIKoe_49878"
            class="pt-1">
          <p class="mt-6">
            บริษัท เจนเทิลวูแมน จำกัด (สำนักงานใหญ่) <br>
            999/9 อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์ <br>
            ห้องเลขที่ OFMH 3806-3812 ชั้นที่ 38 ถนนพระราม 1 <br>
            แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330
          </p>
        </div>
        <div class="w-haft text-left">
          <!-- <div class="credit-note-text"></div> -->
          <tr>
            <th class="px-4 pt-1">
              เลขที่
            </th>
            <td class="px-4 pt-1">
              {{ fabricOrderId }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              วันที่ทำรายการ
            </th>
            <td class="px-4 pt-1">
              {{ fabricOrderCreateDate | dateTimeFormat() }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              โรงงาน
            </th>
            <td class="px-4 pt-1">
              {{ fac }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              ผ้าทั้งหมด
            </th>
            <td class="px-4 pt-1">
              {{ getFabricsByFactory(fac).length }} รายการ
            </td>
          </tr>
        </div>
      </div>
      <div class="mt-4 product-table">
        <table style="width: 100%">
          <tr>
            <th class="px-4 py-1 table-head">
              รหัส
            </th>
            <th class="px-4 py-1 table-head">
              แฮงค์
            </th>
            <th class="px-4 py-1 table-head">
              เนื้อผ้า
            </th>
            <th class="px-4 py-1 table-head">
              ลายผ้า
            </th>
            <th class="px-4 py-1 table-head">
              จำนวน
            </th>
            <th class="px-4 py-1 table-head">
              โรงงาน
            </th>
          </tr>
          <tr
            v-for="(fabric, index) of getFabricsByFactory(fac)"
            :key="index">
            <td class="px-4 py-1 text-center border-b">
              {{ fabric.fabricNo }}
            </td>
            <td class="px-4 py-1 text-center border-b">
              {{ fabric.hangId }}
            </td>
            <td class="px-4 py-1 text-center border-b">
              <a
                v-for="(img, i) of fabric.imageUrls"
                :key="i"
                :href="img"
                target="_blank">
                <img
                  :src="img"
                  width="auto"
                  class="mb-3 mx-auto"
                  style="max-height: 120px;" />
              </a>
            </td>
            <td class="px-4 py-1 text-center border-b">
              <a
                v-for="(img, i) of fabric.patterns"
                :key="i"
                :href="img"
                target="_blank">
                <img
                  :src="img"
                  width="auto"
                  class="mb-3 mx-auto"
                  style="max-height: 120px;" />
              </a>
            </td>
            <td class="px-4 py-1 text-center border-b">
              {{ fabric.amount | showNumberFormat() }}
            </td>
            <td class="px-4 py-1 text-center border-b">
              {{ fabric.dest }}
            </td>
          </tr>
        </table>
      </div>
      <!-- <div class="mt-10 d-flex justify-end">
        <div class="summary">
          <div class="d-flex justify-space-between">
            <span class="text-bold">
              ยอดรวมสุทธิ
            </span>
            <span>
              {{ getTotalPrice(getFabricsByFactory(fac)) | showFullPriceFormat() }} บาท
            </span>
          </div>
        </div>
      </div> -->
    </div>
    <div
      v-for="(fac, f) of materialFactories"
      :key="`mat-${f}`"
      size="A4"
      class="credit-note">
      <div class="d-flex">
        <div class="w-haft">
          <img
            width="50"
            height="50"
            alt="gw"
            src="https://s3-ap-southeast-1.amazonaws.com/storage-oms.akitahub/20200615111959uYgipGIKoe_49878"
            class="pt-1">
          <p class="mt-6">
            บริษัท เจนเทิลวูแมน จำกัด (สำนักงานใหญ่) <br>
            999/9 อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์ <br>
            ห้องเลขที่ OFMH 3806-3812 ชั้นที่ 38 ถนนพระราม 1 <br>
            แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330
          </p>
        </div>
        <div class="w-haft text-left">
          <!-- <div class="credit-note-text"></div> -->
          <tr>
            <th class="px-4 pt-1">
              เลขที่
            </th>
            <td class="px-4 pt-1">
              {{ fabricOrderId }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              วันที่ทำรายการ
            </th>
            <td class="px-4 pt-1">
              {{ fabricOrderCreateDate | dateTimeFormat() }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              โรงงาน
            </th>
            <td class="px-4 pt-1">
              {{ fac }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              ผ้าทั้งหมด
            </th>
            <td class="px-4 pt-1">
              {{ getMaterialsByFactory(fac).length }} รายการ
            </td>
          </tr>
        </div>
      </div>
      <div class="mt-4 product-table">
        <table style="width: 100%">
          <tr>
            <!-- <th class="px-4 py-1 table-head">
              รหัส
            </th> -->
            <th class="px-4 py-1 table-head">
              รูปภาพ
            </th>
            <th class="px-4 py-1 table-head">
              ลายอะไหล่
            </th>
            <th class="px-4 py-1 table-head">
              ชื่อ
            </th>
            <th class="px-4 py-1 table-head">
              จำนวน
            </th>
            <th class="px-4 py-1 table-head">
              โรงงาน
            </th>
          </tr>
          <tr
            v-for="(material, index) of getMaterialsByFactory(fac)"
            :key="index">
            <!-- <td class="px-4 py-1 text-center border-b">
              {{ material.id }}
            </td> -->
            <td class="px-4 py-1 text-center border-b">
              <a
                v-for="(img, i) of material.imageUrls"
                :key="i"
                :href="img"
                target="_blank">
                <img
                  :src="img"
                  width="auto"
                  class="mb-3 mx-auto"
                  style="max-height: 120px;" />
              </a>
            </td>
            <td class="px-4 py-1 text-center border-b">
              <a
                v-for="(img, i) of material.patterns"
                :key="`material-pattern-${i}`"
                :href="img"
                target="_blank">
                <img
                  :src="img"
                  width="auto"
                  class="mb-3 mx-auto"
                  style="max-height: 120px;" />
              </a>
            </td>
            <td class="px-4 py-1 text-left border-b">
              <p>
                {{ material.name }}
              </p>
              <div class="d-flex flex-column">
                <span>
                  <strong>สี: </strong>
                  {{ material.color }}
                </span>
                <span>
                  <strong>วัสดุ: </strong>
                  {{ material.material }}
                </span>
                <span>
                  <strong>ขนาด: </strong>
                  {{ material.size }}
                </span>
              </div>
            </td>
            <td class="px-4 py-1 text-center border-b">
              {{ material.amount | showNumberFormat() }}
            </td>
            <td class="px-4 py-1 text-center border-b">
              {{ material.dest }}
            </td>
            <!-- <td class="px-4 py-1 text-right border-b">
              {{ calcPrice(material.unitPrice, material.currency) | showFullPriceFormat() }} บาท
            </td>
            <td class="px-4 py-1 text-right border-b">
              {{ calcPrice(material.unitPrice * material.amount, material.currency) | showFullPriceFormat() }} บาท
            </td> -->
          </tr>
        </table>
      </div>
      <!-- <div class="mt-10 d-flex justify-end">
        <div class="summary">
          <div class="d-flex justify-space-between">
            <span class="text-bold">
              ยอดรวมสุทธิ
            </span>
            <span>
              {{ getTotalPrice(getMaterialsByFactory(fac)) | showFullPriceFormat() }} บาท
            </span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fabricOrder: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    fabricOrderId () {
      return this.fabricOrder?.id || '-'
    },
    fabricOrderCreateDate () {
      return this.fabricOrder?.createdAt || null
    },
    materials () {
      const allMaterials = this.fabricOrder.prototypes.reduce((all, p) => [
        ...all,
        ...p.order.colors.reduce((arr, c) => [
          ...arr,
          ...c.materials.map((m) => ({
            ...m,
            patterns: m?.patterns ? m.patterns.reduce((items, item) => [...items, ...item.imageUrls], []) : [],
            mainColor: c.color,
            dest: p.sketch.factory.name
          }))
        ], [])
      ], [])

      const groupped = this.groupByKeys(allMaterials, ['id'])
      const mapped = []

      Object.keys(groupped).forEach((g) => {
        const group = groupped[g]

        mapped.push({
          ...group[0],
          amount: group.reduce((sum, item) => sum + item.amount, 0)
        })
      })

      return mapped
    },
    fabrics () {
      const allFabrics = this.fabricOrder.prototypes.reduce((all, p) => [
        ...all,
        ...p.order.colors.reduce((arr, c) => [
          ...arr,
          ...c.fabrics.map((f) => ({
            ...f,
            patterns: f.patterns.reduce((items, item) => [...items, ...item.imageUrls], []),
            mainColor: c.color,
            dest: p.sketch.factory.name
          }))
        ], [])
      ], [])

      const groupped = this.groupByKeys(allFabrics, ['id'])
      const mapped = []

      Object.keys(groupped).forEach((g) => {
        const group = groupped[g]

        mapped.push({
          ...group[0],
          amount: group.reduce((sum, item) => sum + item.amount, 0)
        })
      })

      return mapped
    },
    materialFactories () {
      return [...new Set(this.materials.map((m) => m.factory))].sort((a, b) => a.localeCompare(b))
    },
    fabricsFactories () {
      return [...new Set(this.fabrics.map((f) => f.factory))].sort((a, b) => a.localeCompare(b))
    }
  },
  methods: {
    calcPrice (price, currency) {
      if (currency === 'cny') {
        return price * 5
      }

      return price
    },
    getMaterialsByFactory (factory) {
      return this.materials.filter((m) => m.factory === factory)
    },
    getFabricsByFactory (factory) {
      return this.fabrics.filter((m) => m.factory === factory)
    },
    getTotalPrice (items) {
      return items.reduce((s, item) => s + this.calcPrice((item.amount * item.unitPrice), item.currency), 0)
    },
    groupByKeys (items, by) {
      const result = {}

      for (const item of items) {
        const key = by.map((prop) => item[prop]).join(';')

        if (result[key]) {
          result[key].push(item)
        } else {
          result[key] = [item]
        }
      }

      return result
    }
  }
}
</script>

<style>
  .product-table {
    min-height: 560px;
  }
  .summary {
    padding: 12px;
    width: 300px;
    background-color: #f6f6f6;
  }
  .table-head {
    background-color: #f6f6f6;
    border-bottom: 1px solid #000;
  }
  .border-b {
    border-bottom: 1px solid #000;
  }
  .credit-note {
    background: #fff;
    width: 210mm;
    min-height: 297mm;
    display: block;
    margin: 40px auto;
    padding: 14mm 15mm;
    font-size: 14px;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    color: #000;
  }
  .w-haft {
    width: 50%;
  }
  .credit-note-text {
    height: 70px;
    /* font-size: 36px;
    font-weight: 600;
    text-align: right; */
  }
  @media print {
    #sidebar {
      display: none !important;
    }
    .v-sheet.theme--light.v-toolbar.v-toolbar--dense.v-app-bar.v-app-bar--fixed {
      display: none !important;
    }
    .credit-note {
      /* position: fixed !important;
      top: 0px !important;
      left: 0px !important; */
      page-break-after: always;
      page-break-before: always;
      clear: both;
    }
  }
</style>

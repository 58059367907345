<template>
  <v-main>
    <div
      size="A4"
      layout="landscape"
      class="page-a4 container elevation-5">
      <export-fabric-order
        v-if="fabricOrder"
        :fabric-order="fabricOrder" />
      <template v-else>
        <div
          v-for="prototype in oldPrototypes"
          :key="prototype.id">
          <manufac-for-export :prototype="prototype" />
        </div>
      </template>
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import ManufacForExport from '../components/ManufacForExport.vue'
import ExportFabricOrder from '../components/ExportFabricOrder.vue'

export default {
  components: {
    ManufacForExport,
    ExportFabricOrder
  },
  computed: {
    ...mapGetters({
      prototypes: 'Prototypes/prototypes',
      fabricOrder: 'Prototypes/fabricOrder'
    }),
    oldPrototypes () {
      return this.prototypes.filter((p) => !this.isNewData(p))
    }
  },
  mounted () {
    window.print()
  },
  methods: {
    isNewData (prototype) {
      return prototype.order ? 'colors' in prototype.order : false
    }
  }
}
</script>

<style lang="scss">
div[size="A4"][layout="landscape"] {
  display: block;
  width: 297mm;
  min-height: 210mm;
  margin: 10px auto;
  padding: calc(12.7mm - 24px);
  background-color: #ffffff;
  word-wrap: break-word;
  page-break-after: always;
  .col {
    position: relative;
  }
  .buttun-remove-product {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}
// #app div[size=A4] .col {
//   display: inline-block;
//   flex-basis: unset;
//   -webkit-box-flex: unset;
//   flex-grow: unset;
// }
// #app div[size=A4] .row {
//   display: block;
//   width: 100%;
// }
// #app div[size=A4] .row + .row {
//   margin-top: 0;
// }
.page-break-after {
  display: none;
}
@media print {
  .page-break {
    page-break-before: avoid;
    page-break-inside: avoid;
  }
  .page-break-after {
    display: block;
    page-break-after: always;
  }
}
</style>
